import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Checkbox, Link, LinkButton, Typography } from '@which/seatbelt'
import { dynamicGa4DataLayerPush } from '@which/shared'

import { useKindeAuth } from '@kinde-oss/kinde-auth-react'
import otel, { SpanStatusCode } from '@opentelemetry/api'

import { getQueryString } from '../../../shared'
import { isLocal } from '../../../shared'
import { paywallUrls } from '../../../shared/paywall-urls'
import { FormFields } from '../components/FormFields'
import { PasswordFieldView } from '../components/PasswordField'
import { Links, LoginPageBannerText, UsernameField } from '../data'
import { getIsValidReferrer } from '../utils'
import type { LoginFormProps } from './LoginPage'
import styles from './LoginPage.module.scss'
import type { LoginState } from './validateUserIdentifier'
import { validateUserIdentifier } from './validateUserIdentifier'

const TwoStepLoginForm: FC<LoginFormProps> = ({
  handleOnSubmit,
  loading: loginAttemptLoading,
  stayLoggedInState,
  showAuthenticationError,
}) => {
  const { stayLoggedIn, setStayLoggedIn } = stayLoggedInState
  const [userIdentifier, setUserIdentifier] = useState<string | null>(null)
  const [loginState, setLoginState] = useState<LoginState>('USER_IDENTIFIER_REQUIRED')

  const kinde = useKindeAuth()
  const [paywallState] = useState(paywallUrls())
  const methods = useForm({ mode: 'onBlur' })
  const { handleSubmit } = methods

  const fetchKindeRedirectUrl = () => {
    let kindeRedirectUrl = window.origin
    const maybeReturnUrl = getQueryString('return_url')
    const returnUrlParamPresent = typeof maybeReturnUrl === 'string'

    const maybeReferrerPath = getQueryString('referrer_path')
    const referrerPathParamPresent = typeof maybeReferrerPath === 'string'
    const isValidReferrer = getIsValidReferrer(document?.referrer)
    if (returnUrlParamPresent) {
      kindeRedirectUrl = `${location.origin}/${maybeReturnUrl}`
    } else if (isValidReferrer && referrerPathParamPresent) {
      const referrerUrl = new URL(document.referrer)
      kindeRedirectUrl = `${referrerUrl.origin}/${maybeReferrerPath}`
    } else if (isValidReferrer) {
      kindeRedirectUrl = document.referrer
    }

    return kindeRedirectUrl
  }

  useEffect(() => {
    if (userIdentifier !== null && paywallState && paywallState.paywallUrl) {
      setLoginState('AUTH_STATE_LOADING')
      validateUserIdentifier(paywallState.paywallUrl, userIdentifier).then(setLoginState)
    }
  }, [userIdentifier, paywallState])

  useEffect(() => {
    if (loginState === 'KINDE_LOGIN') {
      dynamicGa4DataLayerPush({
        event: 'click_button',
        item_text: 'Log in',
      })

      const domain = isLocal() ? 'localhost' : '.which.co.uk'

      if (!kinde.isAuthenticated) {
        document.cookie = `sessionId=temp;path=/;domain=${domain};expires=Fri, 31 Dec 9999 23:59:59 GMT`
        document.cookie = `blaize_session=temp;path=/;domain=${domain};expires=Fri, 31 Dec 9999 23:59:59 GMT`
      }

      sessionStorage.setItem('kindeRedirect', fetchKindeRedirectUrl())
      kinde.login({
        connectionId: paywallState.kindeConnectionId,
        loginHint: userIdentifier || '',
      })
    }
  }, [loginState, kinde, showAuthenticationError, userIdentifier, paywallState])

  useEffect(() => {
    if (loginState === 'INCORRECT_USER_IDENTIFIER') {
      showAuthenticationError(LoginPageBannerText.userIdentifier)
    }
  }, [loginState, showAuthenticationError])

  const resetForm = () => {
    setLoginState('USER_IDENTIFIER_REQUIRED')
    methods.resetField('password')
    setUserIdentifier(null)
  }

  const handleEmailSubmit = handleSubmit((formObj, e) => {
    e?.preventDefault()
    setUserIdentifier(formObj.username)
  })

  const handlePasswordSubmit = handleSubmit((formObj, e) => {
    e?.preventDefault()
    dynamicGa4DataLayerPush({
      event: 'click_button',
      item_text: 'Log in',
    })
    if (userIdentifier && formObj.password) {
      handleOnSubmit(userIdentifier, formObj.password, stayLoggedIn)
    } else {
      resetForm()
      if (typeof otel !== 'undefined') {
        const span = otel?.trace?.getActiveSpan()
        span?.setStatus({ code: SpanStatusCode.ERROR })
        span?.setAttribute('error.statusCode', 401)

        if (!userIdentifier) {
          span?.recordException(new Error('Login error: missing userIdentifier'))
        }

        if (!formObj.password) {
          span?.recordException(new Error('Login error: missing password'))
        }
      }
      handleOnSubmit(userIdentifier as string, formObj.password, stayLoggedIn)
    }
  })

  const isPasswordStep = loginState === 'ZEPHR_LOGIN'

  return (
    <>
      <FormProvider {...methods}>
        <form method="post" onSubmit={isPasswordStep ? handlePasswordSubmit : handleEmailSubmit}>
          {!isPasswordStep && (
            <>
              <FormFields fields={UsernameField} />
              <Button
                type="submit"
                data-testid="continue-button"
                enableSpinner={loginState === 'AUTH_STATE_LOADING'}
                className={styles.loginPageSubmit}
              >
                Continue
              </Button>
            </>
          )}

          {isPasswordStep && (
            <Typography textStyle="sb-text-body-x-small-regular" className={styles.feedbackSurvey}>
              <Typography tag="span" textStyle="sb-text-body-default-regular">
                {userIdentifier}{' '}
              </Typography>
              <LinkButton
                data-testid="go-back-button"
                onClick={resetForm}
                textStyle="sb-text-interface-body-default-regular"
              >
                Edit
              </LinkButton>
            </Typography>
          )}

          <div
            data-testid="password-container"
            aria-hidden={isPasswordStep ? 'false' : 'true'}
            style={isPasswordStep ? {} : { display: 'none' }}
          >
            <PasswordFieldView
              displayPasswordCheckList={false}
              name="password"
              label="Password"
              rulesRequired={false}
              calledFrom="login"
              errorMessageText="Please enter your password"
              maxLength="50"
              showRequiredText={false}
              autoComplete="password"
              validation={{ required: isPasswordStep }}
            />
          </div>

          {isPasswordStep && (
            <>
              <div className={styles.loginPageFormFooter}>
                <Checkbox
                  type="checkbox"
                  className={styles.loginPageStayLoggedInDiv}
                  name="stay-logged-in"
                  id="stay-logged-in"
                  value=""
                  label="Stay logged in"
                  checked={stayLoggedIn}
                  onChangeCallback={() => setStayLoggedIn(!stayLoggedIn)}
                />
                <div className={styles.loginPageForgotPassword}>
                  <Link
                    appearance="primary"
                    href={Links.forgotPassword}
                    textStyle="sb-text-interface-body-small-regular"
                    data-which-id="link"
                    className={styles.loginPageForgotPasswordLink}
                  >
                    Forgotten password?
                  </Link>
                </div>
              </div>
              <Typography
                textStyle="sb-text-body-x-small-regular"
                className={styles.loginPageUntickText}
              >
                Untick if you're using a public device
              </Typography>
              <Button
                type="submit"
                data-testid="login-button"
                enableSpinner={loginAttemptLoading}
                className={styles.loginPageSubmit}
              >
                Log in
              </Button>
            </>
          )}
        </form>
      </FormProvider>

      {isPasswordStep && (
        <FormProvider {...methods}>
          <form method="post" onSubmit={handlePasswordSubmit}></form>
        </FormProvider>
      )}
    </>
  )
}

export default TwoStepLoginForm
