import React from 'react'
import { TypographyV2 as Typography } from '@which/seatbelt'
import { PlusBoldIcon, TickBoldIcon } from '@which/seatbelt/src/components/Icons/Miscellaneous'

import styles from './HowWeTest.module.scss'

const iconTick = 'tick'

const renderCriteriaItem = (testCriteria, index, totalItemsToDisplay) => {
  let IconToRender

  if (testCriteria.icon) {
    testCriteria.icon === iconTick ? (IconToRender = TickBoldIcon) : (IconToRender = PlusBoldIcon)
  } else {
    index + 1 < totalItemsToDisplay ? (IconToRender = TickBoldIcon) : (IconToRender = PlusBoldIcon)
  }

  return (
    <div>
      <div className={styles.heading}>
        <div className={styles.iconContainer}>
          <IconToRender className={styles.icon} />
        </div>
        <div>
          <Typography textStyle="sb-text-interface-heading-small">{testCriteria.header}</Typography>
          <Typography textStyle="sb-text-body-small-regular" className={styles.description}>
            {testCriteria.description}
          </Typography>
        </div>
      </div>
    </div>
  )
}

export const HowWeTest = ({ title, testCriterias }: Props) => {
  if (!testCriterias) {
    return null
  }

  const totalItemsToDisplay = testCriterias.length

  return (
    <div className={styles.howWeTest} data-testid="how-we-test">
      <Typography textStyle="sb-text-heading-small" className={styles.title}>
        {title || 'How our tests find you the best'}
      </Typography>

      <div className={styles.howWeTestGrid}>
        {testCriterias &&
          testCriterias.map((testCriteria, index) =>
            renderCriteriaItem(testCriteria, index, totalItemsToDisplay)
          )}
      </div>
    </div>
  )
}

type Props = {
  title?: string
  testCriterias?: {
    icon?: 'tick' | 'plus'
    header: string
    description: string
  }[]
}
