import { type FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Grid, GridItem, PageTitle, TypographyV2 as Typography } from '@which/seatbelt'
import { dynamicDatalayerPush } from '@which/shared'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { useKindeAuth } from '@kinde-oss/kinde-auth-react'

import type { RegisterMutation } from '../../../generated/frontend'
import { useRegisterMutation } from '../../../generated/frontend'
import { ErrorComponent } from '../../../shared/components/Error/ErrorComponent'
import { Link } from '../../../shared/components/Link'
import { useGetQueryString } from '../../../shared/hooks/useGetQueryString'
import { paywallUrls } from '../../../shared/paywall-urls'
import { isLocal } from '../../../shared/utils/env-checks'
import { BundleDetails } from '../components/BundleDetails'
import { ErrorModal } from '../components/ErrorModal'
import { FormFields } from '../components/FormFields'
import { MarketingPreferences } from '../components/MarketingPreferences'
import { PasswordFieldView } from '../components/PasswordField'
import { QuestionAccordion } from '../components/QuestionAccordion'
import { RegistrationFields } from '../data'
import {
  accountPagesDataLayer,
  findBlaizeSessionAvailable,
  findWhichDomain,
  setTransactionName,
} from '../utils'
import style from './RegistrationPage.module.scss'

interface FormValues {
  firstName: string
  lastName: string
  email: string
  password: string
  postConsent: 'Yes' | 'No'
  emailConsent: 'Yes' | 'No'
}

const PASSWORD_REGEX =
  /^(?=.*[a-zA-Z])(?=.*[0-9!#£$%^&*()_+="\-])(?!.*[@#;?<.,>{/}'\\|\[\]]).{8,32}$/

const RegistrationPage: FunctionComponent = () => {
  const [sessionChecked, setSessionChecked] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [httpErrorCode, setHttpErrorCode] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const paywallState = useMemo(paywallUrls, [])
  const kinde = useKindeAuth()

  /* @TODO: ee-247 - remove after EE-247 test ends */
  const showBundleDetails = useFeatureIsOn('ee-247-add-selected-subscription-details')
  const isKindeRegisterFlagEnabled = useFeatureIsOn('paywall-register-with-kinde-as-primary')
  const bundleId = useGetQueryString('ltm_gaBundleID')
  const limioRedirectUrl = useGetQueryString('redirect')

  const [registerMutation, { loading, error }] = useRegisterMutation()

  const methods = useForm<FormValues>({ mode: 'onBlur' })
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods

  const handleOnCompleted = useCallback(
    (email: string) => (data?: RegisterMutation) => {
      if (!data?.register) {
        return
      }
      const { register } = data
      switch (register.__typename) {
        case 'Registration':
          if (['200', '201'].includes(register.status)) {
            const domain = isLocal() ? 'localhost' : '.which.co.uk'
            if (isKindeRegisterFlagEnabled) {
              if (!kinde.isAuthenticated) {
                document.cookie = `sessionId=temp;path=/;domain=${domain};expires=Fri, 31 Dec 9999 23:59:59 GMT`
                document.cookie = `blaize_session=temp;path=/;domain=${domain};expires=Fri, 31 Dec 9999 23:59:59 GMT`
              }
              sessionStorage.setItem(
                'kindeRedirect',
                findWhichDomain(limioRedirectUrl ?? undefined) || 'https://www.which.co.uk/'
              )
              kinde.login({
                connectionId: paywallState.kindeConnectionId,
                loginHint: email,
              })
            } else {
              const zephrCookies = register.headers['set-cookie']
              zephrCookies.forEach((cookie: string) => {
                if (cookie.indexOf('blaize_') > -1) {
                  document.cookie = `${cookie};domain=${domain};`
                } else {
                  document.cookie = cookie
                }
              })
              if (register.masterId) {
                document.cookie = `master_id.analytics_flag=1;domain=${domain}`
                document.cookie = `master_id=${register.masterId};domain=${domain}`
              }
              window.location.href =
                findWhichDomain(limioRedirectUrl ?? undefined) || 'https://www.which.co.uk/'
            }
          }
          break
        case 'RegistrationError':
          setHttpErrorCode(register.status)
          setShowModal(true)
          break
      }
    },
    [kinde, limioRedirectUrl, paywallState.kindeConnectionId, isKindeRegisterFlagEnabled]
  )

  const handleOnSubmit = useCallback(
    ({ firstName, lastName, email, password, postConsent, emailConsent }: FormValues) => {
      setEmailAddress(email)

      registerMutation({
        variables: {
          registerRequest: {
            firstName,
            lastName,
            email,
            password,
            individual: {
              isPostOptIn: postConsent === 'Yes',
              isEmailOptIn: emailConsent === 'Yes',
            },
          },
        },
        onCompleted: handleOnCompleted(email),
      })
    },
    [registerMutation, handleOnCompleted]
  )

  useEffect(() => {
    setTransactionName('account/registration')
  }, [])

  useEffect(() => {
    if (
      isSubmitting &&
      errors &&
      Object.keys(errors).length !== 0 &&
      Object.getPrototypeOf(errors) === Object.prototype
    ) {
      const fields = Object.keys(errors).join(' | ')
      dynamicDatalayerPush({
        eventCategory: 'Signup',
        eventAction: 'Form Validation Errors',
        eventLabel: fields.toString(),
      })
      const elements = Object.keys(errors)
        .map((name) => document.getElementsByName(name)[0])
        .filter((el) => !!el)
      elements.sort((a, b) => b.scrollHeight - a.scrollHeight)
      elements[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [errors, isSubmitting])

  useEffect(() => {
    if (findBlaizeSessionAvailable('blaize_session')) {
      window.location.href = findWhichDomain(limioRedirectUrl ?? undefined)
    } else {
      setSessionChecked(true)
    }
  }, [limioRedirectUrl])

  if (error) {
    const { graphQLErrors } = error
    const extensionCode = graphQLErrors[0]?.extensions?.code as string
    if (['500', '400', '504'].includes(extensionCode)) {
      setHttpErrorCode(extensionCode)
      setShowModal(true)
    } else {
      return <ErrorComponent error={error} />
    }
  }

  if (!sessionChecked) {
    return (
      <Helmet>
        <meta name="robots" content="noindex" />
        <title>Create an account - Which?</title>
        <script>
          {accountPagesDataLayer({
            content_type: 'signup',
            vertical: 'signup',
            contentGroup: 'join/begin',
          })}
        </script>
      </Helmet>
    )
  }

  const bundleDetails = showBundleDetails && bundleId ? <BundleDetails bundleId={bundleId} /> : null

  return (
    <Grid className={style.registrationPage}>
      <ErrorModal
        httpErrorCode={httpErrorCode}
        emailAddress={emailAddress}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <GridItem span={{ medium: 6, large: 6 }} columnStart={{ medium: 4, large: 4 }}>
        {bundleDetails}
        <PageTitle pageTitle="Create an account" pageTitleTag="h1" />
        <Typography
          textStyle="sb-text-body-x-small-regular"
          className={style.registrationPageLogin}
        >
          Already have an account?{' '}
          <Link href="/login" textStyle="sb-text-interface-body-x-small-regular">
            Log in to Which?
          </Link>
        </Typography>
        <FormProvider {...methods}>
          <form method="post">
            <FormFields fields={RegistrationFields} />
            <PasswordFieldView
              displayPasswordCheckList={true}
              name="password"
              label="Password"
              rulesRequired={true}
              calledFrom="registration"
              errorMessageText="Please enter a valid password"
              showRequiredText={true}
              autoComplete="new-password"
              validation={{
                required: true,
                pattern: PASSWORD_REGEX,
              }}
            />
            <MarketingPreferences />
            <Button
              data-testid="sign-up-button"
              enableSpinner={loading}
              onClick={handleSubmit(handleOnSubmit)}
              className={style.registrationPageSubmit}
            >
              Save and continue
            </Button>
          </form>
        </FormProvider>
        <QuestionAccordion
          label="Any questions?"
          content={<AccordionContent />}
          calledFrom="registration"
        />
      </GridItem>
    </Grid>
  )
}
export default RegistrationPage

const AccordionContent: React.FC = () => (
  <>
    <Typography textStyle="sb-text-body-default-regular">
      If you have any questions about joining Which? please get in touch by phone or email:
    </Typography>
    <Typography textStyle="sb-text-body-default-regular">
      <Link href="tel:+02922670000">02922670000</Link>
      <br />
      Mon - Fri, 8:30am to 6pm
      <br />
      Sat, 9am to 1pm
      <br />
      Standard call rates apply
    </Typography>
    <Link href="mailto:support@which.co.uk">support@which.co.uk</Link>
  </>
)
